html {
    background: #eee;  
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: #fff;
}

h2 {
    font-size: 1.2em;
}

h3 {
    font-size: 1.1em;
}

#legacy {
    padding: 16px;
}

@import './cppmem_js.css';

@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/material.css';
